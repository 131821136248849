.footer {
  background: rgba(33, 37, 41, 0.95);
  backdrop-filter: blur(8px);
  padding: 1.5rem 0;
  box-shadow: 0 -2px 10px rgba(0,0,0,0.1);
}

.copyright {
  color: rgba(255,255,255,0.8);
  margin: 0;
  font-size: 0.9rem;
}

.socialLinks {
  display: flex;
  justify-content: flex-end;
  gap: 1rem;
}

.socialLinks a {
  color: rgba(255,255,255,0.8);
  font-size: 1.25rem;
  transition: all 0.2s ease;
}

.socialLinks a:hover {
  color: #fff;
  transform: translateY(-2px);
}

@media (max-width: 768px) {
  .copyright, .socialLinks {
    text-align: center;
    justify-content: center;
  }
  .copyright {
    margin-bottom: 1rem;
  }
}