.homePage {
    min-height: 100vh;
    background: linear-gradient(to bottom, #f8f9fa, #fff);
}

.heroSection {
    padding: 2rem 0;
}

.carousel {
    border-radius: 12px;
    overflow: hidden;
    box-shadow: 0 4px 20px rgba(0,0,0,0.15);
}

.carouselLink {
    position: relative;
    display: block;
}

.overlay {
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background: linear-gradient(to top, rgba(0,0,0,0.7), transparent);
}

.caption {
    z-index: 2;
    text-align: center;
    padding: 1rem;
}

.caption h3 {
    font-size: clamp(1rem, 3vw, 1.5rem);
    font-weight: 600;
    text-shadow: 2px 2px 4px rgba(0,0,0,0.5);
    margin: 0;
}

@media (max-width: 768px) {
    .caption {
        padding: 0.5rem;
    }
}

.socialsSection {
    padding: 4rem 0;
}

.socialLink {
    display: inline-flex;
    align-items: center;
    gap: 0.75rem;
    padding: 0.75rem 1.5rem;
    border-radius: 8px;
    color: #fff;
    text-decoration: none;
    transition: transform 0.2s ease, box-shadow 0.2s ease;
}

.socialLink:hover {
    transform: translateY(-2px);
    box-shadow: 0 4px 12px rgba(0,0,0,0.15);
    color: #fff;
}

.twitch {
    background: #6441a5;
}

.youtube {
    background: #ff0000;
}