@import url("https://fonts.googleapis.com/css2?family=Poppins:wght@300;400&display=swap");

* {
  box-sizing: border-box;
  margin: 0;
  padding: 0;
}

body {
  font-family: "Roboto", -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, "Helvetica Neue", Arial, "Noto Sans", sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol", "Noto Color Emoji";
}

.app-container {
  min-width: 99%;
  margin: 30px auto;
  overflow: auto;
  min-height: 100%;
  border: 1px solid steelblue;
  padding: 30px;
  border-radius: 5px;
}

.title {
  font-weight: bold;
  align-items: center;
  justify-content: space-between;
  font-size: 1.5rem;
  margin-bottom: 0;
}

.field-entry {
  background: #f4f4f4;
  margin: 5px;
  padding: 10px 20px;
  display: grid;
}

.league-entry {
  box-shadow: 0 0 0 2px lightblue inset;
}

.history-entry {
  background: #f4f4f4;
  margin: 5px;
  padding: 10px 20px;
  cursor: pointer;
}
.history-entry-data {
  grid-template-columns: 2fr 2fr 2fr 1fr;
}

.nav-link {
  color: steelblue;
}

footer {
  margin-top: 30px;
  text-align: center;
}

.center-header {
  text-align: center;
}

a.button {
  text-decoration: none;
  color: initial;
  float: right;
}
input[type="text"],
select {
  width: 100%;
  padding: 12px 20px;
  margin: 8px 0;
  display: inline-block;
  border: 1px solid #ccc;
  border-radius: 4px;
}
input[type="number"] {
  padding: 12px 20px;
  margin: 8px 0;
  display: inline-block;
  border: 1px solid #ccc;
  border-radius: 4px;
}

.error {
  width: 100%;
  padding: 12px 20px;
  margin: 8px 0;
  display: inline-block;
  border: 1px solid red;
  border-radius: 4px;
}
label {
  width: 100%;
}

.command {
  width: 50%;
  background-color: #4caf50;
  color: white;
  padding: 14px 20px;
  margin: 8px 0;
  border: none;
  border-radius: 4px;
  cursor: pointer;
}

.command:hover {
  background-color: #45a049;
}

div {
  border-radius: 5px;
  padding: 4px;
}

.setup {
  column-count: 2;
}
.setup-3 {
  column-count: 3;
}
.setup-4 {
  column-count: 4;
}

.bordered {
  border: 1px solid;
  border-radius: 10px;
  border-color: #bbb;
  padding: 14px 20px;
}

.session-section {
  grid-template-columns: auto auto;
}

.column {
  grid-column: 1/2;
}

.firstPlace {
  font-size: 1.2em;
  font-weight: bold;
}
.trophy {
  padding-right: 5px;
}
.lessVerticalPadding {
  padding-top: 0px;
  padding-bottom: 0px;
}
.display-flex {
  display: flex;
}

.league-badge {
  float: right;
}
.league-badge:hover {
  outline-style: solid;
  outline-width: 1px;
  background-color: white !important;
  outline-color: rgba(var(--bs-info-rgb), var(--bs-bg-opacity));
  color: rgba(var(--bs-info-rgb), var(--bs-bg-opacity));
}

.session-details-badge {
  float: right;
}

.session-details-badge:hover {
  outline-style: solid;
  outline-width: 1px;
  background-color: white !important;
  outline-color: rgba(var(--bs-secondary-rgb), var(--bs-bg-opacity));
  color: rgba(var(--bs-secondary-rgb), var(--bs-bg-opacity));
}
.info-marker {
  float: right;
  padding: 0px;
  margin: 3px 5px;
}
.info-marker > svg {
  display: block;
}
.league-config-panel {
  border-left: 1px dashed #333;
}
.race-complete-row {
  background-color: #9eb5a8;
}
.nav-link{
  text-align: center;
}
.social-name {
  padding: 5px; 
}
.homepage-content {
  text-align: center;
}
.social-list {
  text-align: center;
}
.btn-twitch, .btn-twitch:hover {
  background-color: #6441a5;
  color: white;
}
.btn-youtube,.btn-youtube:hover {
  background-color: red;
  color: white;
}
.fastest-lap-highlight {
  color: purple;
  font-weight: bold;
}
.personal-fastest-lap-highlight {
  color: green;
  font-weight: bold;
}
.personal-fastest-sector-highlight {
  color: green;
  font-weight: bold;
}
.race-event-modal {
  text-align: center;
}
hr {
  margin: 3rem;
}
.homepage-carousel-item{
  height: auto;
  width: 100%;
  overflow: hidden;
}
.homepage-carousel img {
  width: 100%;
  height: 100%;
  object-fit: cover;
}
.homepage-carousel-caption {
  text-shadow: 2px 1px black;
  top: 1.25rem;
}
@media screen and (max-width: 1000px) {
  .history-entry-data-title {
      text-align: center;
  }
}
.session-score td {
  padding-right: 5px; 
  padding-left: 5px;
}
.header-logo {
  max-width: 100%;
}
.leagues-container {
  margin: 0;
}
.league-cardactions {
  position: relative;
}
.league-display-badge {
  position: absolute;
  right: 0px;
  margin: 6px 16px;
}
.standings-row {
  transition: all 0.2s ease-in-out;
}

.standings-row:hover {
  background-color: rgba(0,0,0,0.04);
}

.row-expand-animation {
  transition: all 0.3s ease-out;
}